import React from "react";
import "./adminteam.css";
import parthasarathy from "./assets/img/doctor/operational-team/parthasarathy.jpg";
import major from "./assets/img/doctor/major.jpg";
import shivu from "./assets/img/doctor/shivu.jpg";
import reshma from "./assets/img/doctor/reshma.jpg";
import ramya from "./assets/img/doctor/operational-team/vanaja-dr.png";
import usha from "./assets/img/doctor/usha.jpg";
import CustomTitle from "./CustomTitle";
import { Helmet } from "react-helmet";
import doc0 from "./assets/img/doctor/doc01.png";
import vanaja from "./assets/img/doctor/admin-team/vanaja-dr.png";
import akshata from "./assets/img/doctor/admin-team/akshata.jpg";
import ramesh from "./assets/img/doctor/admin-team/ramesh-cfo.jpg";
import bharath from "./assets/img/doctor/admin-team/bharath-b-k.jpg";
import ashishprasad from "./assets/img/doctor/admin-team/ashishprasad.jpg";
import shivkumar from "./assets/img/doctor/admin-team/shivkumar-ben.jpg";

export default function AdminTeam() {
  const helmetDescriptions = {
    titleName: "Meet Our Admin Team | Kangaroo Care Fertility Experts",
    Description:
      "Meet the Kangaroo Care Fertility admin team, committed to providing exceptional support and personalized care for your fertility needs. Learn more about our experts today.",
  };
  return (
    <>
      <CustomTitle
        href="https://kangaroocarefertility.com/admin-team"
        customValue={helmetDescriptions}
      />
      <div data-aos="fade-up">
        <div className="container-fluid text-center py-4 bg-light">
          {/* <p style={{ color: "#771fbb", fontSize: "32px", fontWeight: "bold" }}>
            Operational Team
          </p> */}
          <h1 className="text-center text-blue">Central Team</h1>

          <div className="container" id="owl-me-carousel-op">
            <div className="row justify-content-center">
              {/* <div className="col-lg-4 col-sm-6" id="cardOwl">
                <div className="card m-2">
                  <img
                    src={parthasarathy}
                    className="card-img-top admin-img"
                    alt="Shivu Senior Manager"
                  />
                  <div className="card-body">
                    <h3 className="card-title text-blue">PARTHASARATHY Y G </h3>
                    <p className="card-text">
                      Center Head
                      <br />
                      Bangalore
                    </p>
                  </div>
                </div>
              </div> */}
              <div className="col-lg-4 col-sm-6 central_team_json" id="cardOwl">
                <div className="card m-2">
                  <img
                    src={doc0}
                    className="card-img-top"
                    alt="Dr. Shekar Subbaiah Neonatologist & CEO"
                  />
                  <div className="card-body">
                    <h3 className="card-title text-blue">
                      Dr. Shekar Subbaiah
                    </h3>
                    <p className="card-text">Founder</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6" id="cardOwl">
                <div className="card m-2">
                  <img
                    src={ramesh}
                    className="card-img-top admin-img"
                    alt="Mr R Ramesh"
                  />
                  <div className="card-body">
                    <h3 className="card-title text-blue">Mr R Ramesh</h3>
                    <p className="card-text">Chief Finance Officer</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-sm-6" id="cardOwl">
                <div className="card m-2">
                  <img
                    src={major}
                    className="card-img-top admin-img"
                    alt="Major Joyshree Head Nursing"
                  />
                  <div className="card-body">
                    <h3 className="card-title text-blue">Major Joyshree</h3>
                    <p className="card-text">Head - Nursing</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6" id="cardOwl">
                <div className="card m-2">
                  <img
                    src={vanaja}
                    className="card-img-top admin-img"
                    alt="MVanaja Ramanna"
                  />
                  <div className="card-body">
                    <h3 className="card-title text-blue">Vanaja Ramanna</h3>
                    <p className="card-text">Zonal Head - Human Resources</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6" id="cardOwl">
                <div className="card m-2">
                  <img
                    src={bharath}
                    className="card-img-top admin-img"
                    alt="Dr Bharath B K"
                  />
                  <div className="card-body">
                    <h3 className="card-title text-blue">Dr Bharath B K</h3>
                    <p className="card-text">Zonal Head - Operations</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6" id="cardOwl">
                <div className="card m-2">
                  <img
                    src={ashishprasad}
                    className="card-img-top admin-img"
                    alt="Major Joyshree Head Nursing"
                  />
                  <div className="card-body">
                    <h3 className="card-title text-blue">Mr Ashish Prasad</h3>
                    <p className="card-text">Trade mark lawyer</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6" id="cardOwl">
                <div className="card m-2">
                  <img
                    src={shivkumar}
                    className="card-img-top admin-img"
                    alt="Shivakumar N M"
                  />
                  <div className="card-body">
                    <h3 className="card-title text-blue">Shivakumar N M</h3>
                    <p className="card-text">Project and Facility Manager</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-sm-6" id="cardOwl">
                <div className="card m-2">
                  <img
                    src={akshata}
                    className="card-img-top admin-img"
                    alt="Akshatha K"
                  />
                  <div className="card-body">
                    <h3 className="card-title text-blue">Akshatha K</h3>
                    <p className="card-text">Marketing Manager</p>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-4 col-sm-6" id="cardOwl">
                <div className="card m-2">
                  <img
                    src={shivu}
                    className="card-img-top admin-img"
                    alt="Shivu Senior Manager"
                  />
                  <div className="card-body">
                    <h3 className="card-title text-blue">SHIVU</h3>
                    <p className="card-text">
                      Senior Manager - Project
                      <br />
                      Bangalore
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-sm-6" id="cardOwl">
                <div className="card m-2">
                  <img
                    src={reshma}
                    className="card-img-top admin-img"
                    alt="Reshma Senior Manager"
                  />
                  <div className="card-body">
                    <h3 className="card-title text-blue">RESHMA</h3>
                    <p className="card-text">
                      Senior Manager - Account <br />
                      Bangalore
                    </p>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-lg-4 col-sm-6" id="cardOwl">
                <div className="card m-2">
                  <img src={vidhya} className="card-img-top admin-img" alt="Vidhya Shree R Senior Manager" />
                  <div className="card-body">
                    <h3 className="card-title text-blue">VIDHYA SHREE R</h3>
                    <p className="card-text">
                      Senior Manager - Human Resourse<br />Bangalore
                    </p>

                  </div>
                </div>
              </div> */}
              {/* <div className="col-lg-4 col-sm-6" id="cardOwl">
                <div className="card m-2">
                  <img
                    src={ramya}
                    className="card-img-top admin-img"
                    alt="Reshma Senior Manager"
                  />
                  <div className="card-body">
                    <h3 className="card-title text-blue">VANAJA RAMANNA</h3>
                    <p className="card-text">
                      Zonal Head - Human Resources <br />
                      Bangalore - Mysore
                    </p>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-lg-4 col-sm-6" id="cardOwl">
                <div className="card m-2">
                  <img
                    src={usha}
                    className="card-img-top admin-img"
                    alt="Usha Reddy Fertility Incharge"
                  />
                  <div className="card-body">
                    <h3 className="card-title text-blue">USHA REDDY</h3>
                    <p className="card-text">
                      Fertility Incharge
                      <br />
                      Bangalore
                    </p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
