import React from "react";
import "./video.css";
import CustomTitle from "../CustomTitle";
import { useLocation, Link } from "react-router-dom";
export default function Image() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const helmetDescriptions = {
    titleName: "Video Gallery | Kangaroo Care Fertility",
    Description:
      "View our video gallery to see our doctor's explanations of IVF, infertility, gynecology, and laparoscopy treatment.",
  };
  return (
    <>
      <CustomTitle
        href="https://kangaroocarefertility.com/gallery/videos"
        customValue={helmetDescriptions}
      />
      {/* <div id="banner-logo">
        <p className="text-center" id="banner-logo-text">
          Photo Gallery of Kangaroo <br /> Care Hospital
        </p>
      </div>
      <nav aria-label="breadcrumb" id="breadcrumb" className="mb-5">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <a href="/gallery/videos/">Gallery</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Videos
          </li>
        </ol>
      </nav> */}
      
      <h1 className="h1-top-text videos-h1-top-text">
        Video Gallery of Kangaroo Care Fertility
      </h1>
      <div className="container mb-4">
        <div className="row">
        <div className="col-md-4 mb-2">
            <div className="p-3 " style={{ backgroundColor: "#eeeeee" }}>
            <iframe
             width="100%" 
             height="240" 
             src="https://www.youtube.com/embed/z1vYNptCPPE?si=G05ilL40dCXJvUE8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowfullscreen
             ></iframe>
              <div id="Having-text-vide">
                <span>
                Kangaroo Care Fertility - Mysore Inauguration
                </span>
              </div>
            </div>
          </div>
          



          <div className="col-md-4 mb-2">
            <div className="p-3 " style={{ backgroundColor: "#eeeeee" }}>
              <iframe
                width="100%"
                height="240"
                src="https://www.youtube.com/embed/I2O4X5BaUU4?si=3tw9ZYkknBKRvckL"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              <div id="Having-text-vide">
                <span>
                  Understanding Your Menstrual Cycle for Pregnancy Planning:
                  Essential Insights from Dr. Divyasree
                </span>
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-2">
            <div className="p-3 " style={{ backgroundColor: "#eeeeee" }}>
              <iframe
                width="100%"
                height="240"
                src="https://www.youtube.com/embed/GfXGoxwhnuU?si=TDDydUjtq8ZWpAsj"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              <div id="Having-text-vide">
                <span>
                  How does Exercise and Diet Support Fertility Wellness
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-2">
            <div className="p-3 " style={{ backgroundColor: "#eeeeee" }}>
              <iframe
                width="100%"
                height="240"
                src="https://www.youtube.com/embed/I4B-l8V1s4k"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              <div id="Having-text-vide">
                <span>
                  Infertility | How does age effects fertility | Kangaroo Care
                  Fertility
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-2">
            <div className="p-3 " style={{ backgroundColor: "#eeeeee" }}>
              <iframe
                width="100%"
                height="240"
                src="https://www.youtube.com/embed/rIQoK8jqnR8"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              <div id="Having-text-vide">
                <span>
                  PCOS | Polycystic Ovary Syndrome | Kangaroo Care Fertility
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-2">
            <div className="p-3 " style={{ backgroundColor: "#eeeeee" }}>
              <iframe
                width="100%"
                height="240"
                src="https://www.youtube.com/embed/HRBfk5x2jd0"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              <div id="Having-text-vide">
                <span>
                  Infertility | Evaluation & Treatment of Infertility | Kangaroo
                  Care Fertility
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-2">
            <div className="p-3 " style={{ backgroundColor: "#eeeeee" }}>
              <iframe
                width="100%"
                height="240"
                src="https://www.youtube.com/embed/0QymkWay_34"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              <div id="Having-text-vide">
                <span>
                  In Vitro Fertilization (IVF) | Intracytoplasmic Sperm
                  Injection (ICSI) | Kangaroo Care Fertility
                </span>
              </div>
            </div>
          </div>

          <div className="col-md-4 mb-2">
            <div className="p-3 " style={{ backgroundColor: "#eeeeee" }}>
              <iframe
                width="100%"
                height="240"
                src="https://www.youtube.com/embed/7iFazvLzh88"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              <div id="Having-text-vide">
                <span>
                  Kangaroo Care Fertility - Your Miracle Our Mission | Best
                  Fertility Hospital in Bangalore
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-2">
            <div className="p-3 " style={{ backgroundColor: "#eeeeee" }}>
              <iframe
                width="100%"
                height="240"
                src="https://www.youtube.com/embed/RqrehOvDalU"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              <div id="Having-text-vide">
                <span>
                  Best maternity hospital in Vijayanagara, Bangalore | Kangaroo
                  Care Fertility.
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-2">
            <div className="p-3 " style={{ backgroundColor: "#eeeeee" }}>
              <iframe
                width="100%"
                height="240"
                src="https://www.youtube.com/embed/C6PKd8LVZmo"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              <div id="Having-text-vide">
                <span>
                  Best fertility center in Bangalore | Kangaroo Care Fertility.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
