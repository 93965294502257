import React from "react";
import "./KcFertilityServices.css";
import banner1 from "../assets/img/home/banners/banner-with-welcome1.jpg";
import { useLocation, Link } from "react-router-dom";
import logo1 from "../kcf_logo1.png";
import img1 from "../assets/img/icons/home-page/ovu.webp";
import img2 from "../assets/img/icons/home-page/IUI.webp";
import img3 from "../assets/img/icons/home-page/ivf1.webp";
import img4 from "../assets/img/icons/home-page/ICSI.webp";
import img5 from "../assets/img/icons/treatment-page/geneticcounselingandtesting.png";
import img6 from "../assets/img/icons/treatment-page/andrology.png";
import img7 from "../assets/img/icons/treatment-page/fertilitypreservation.png";
import simg from "../assets/img/fertility/Intrauterine-Insemination-(IUI)-Banner.jpg";

import serviceImg1 from "../assets/img/landing-page/customised-treatment.png";
import serviceImg2 from "../assets/img/landing-page/ivf-facility.png";
import serviceImg3 from "../assets/img/landing-page/pregnancy.png";
import serviceImg4 from "../assets/img/landing-page/care.png";
import serviceImg5 from "../assets/img/landing-page/rules.png";
import serviceImg6 from "../assets/img/landing-page/improving.png";
import serviceImg7 from "../assets/img/landing-page/highest-level-of-service.png";
import serviceImg8 from "../assets/img/landing-page/patient-care.png";

import OwlCarousel from "react-owl-carousel";
import kcf_white_logo from "./kcf_white_logo.webp";
export default function KcFertilityChanges() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <div className="landing-page-container">
        <div className="container-fluid sticky-top bg-light landing-page-container2">
          <div className="container top-nav-bar ">
            <div className="row bg-til">
              <div className="col-md-6">
                <a href="/">
                  {" "}
                  <img src={logo1} alt=".." />
                </a>
              </div>
              <div className="col-md-6 py-3 d-flex flex-row-reverse">
                <div className="header-form">
                  {/* <a href="tel:+91 8023015678">
                  <div className="btn btn-1 me-2 rounded-pill">
                    Call&nbsp;Now
                  </div>
                </a> */}
                  <a href="#header">
                    <div className="btn btn-2 me-2 rounded-pill">
                      Book&nbsp;Appointment
                    </div>
                  </a>
                  <a href="tel:18004254500 ">
                    <div className="btn btn-2 me-2 rounded-pill">
                      Toll Free - 1800-425-4500
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <header id="header">
          <div className="container myForm-container">
            <div className="row p-sm-4 p-3 justify-content-center">
              <div className="col-lg-8 col-md-6 col-sm-10">
                {/* <h1 className="text-center">
                Most Trusted Experts for Fertility Treatments
              </h1> */}
                <div className="section-top-color ">
                  <h1>Start Your Journey towards Parenthood.!</h1>
                  <h2>
                    Get Advice from the Most Trusted Fertility Specialists.
                  </h2>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-10">
                <div className="landing-form-group">
                  <form
                    onsubmit=""
                    method="post"
                    action="https://digikit.in/cutis-contact-form/kangaroocarefertility/save.php"
                  >
                    <div class="mb-3">
                      <label
                        for="exampleFormControlInput1"
                        class="form-label text-light"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        required
                        id="names"
                        placeholder="Name"
                        name="name"
                      />
                    </div>
                    <div class="mb-3">
                      <label
                        for="exampleFormControlInput1"
                        class="form-label text-light"
                      >
                        Mobile Number ( Indian Number )
                      </label>
                      <input
                        type="tel"
                        pattern="^[6-9]\d{9}$"
                        minlength="10"
                        maxlength="10"
                        class="form-control"
                        required
                        id="mobileNumbers"
                        placeholder="Mobile Number"
                        name="mobile"
                      />
                    </div>
                    <div class="mb-3">
                      <label
                        for="exampleFormControlInput1"
                        class="form-label text-light"
                      >
                        City
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        required
                        id="city"
                        placeholder="City"
                        name="city"
                      />
                    </div>
                    <div class="mb-3">
                      <label
                        for="exampleFormControlInput1"
                        class="form-label text-light"
                      >
                        Email address
                      </label>
                      <input
                        type="email"
                        class="form-control"
                        required
                        id="emails"
                        name="email"
                        placeholder="name@example.com"
                      />
                    </div>

                    <div class="mb-3">
                      <div className="row-mt-3">
                        <label for="message" className="text-light">
                          Message
                        </label>
                        <div className="input-group has-validation mt-1">
                          <textarea
                            className="form-control"
                            name="message"
                            rows="3"
                            placeholder="Message have min length 50 and max length 1000"
                            pattern="[a-zA-Z ]{30,1000}"
                            autocomplete="off"
                            required
                          ></textarea>
                          <div className="valid-feedback">It Looks good!</div>
                          <div className="invalid-feedback">
                            Please Enter Your proper Message without using any
                            specail Character.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mb-3 d-flex justify-content-center align-items-center">
                      <button class="btn btn-success">
                        Book an Appointment
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </header>

        {/* \\\\\\\\\\
      --------
      services
      ------
      ///////
       */}

        <div className="container " id="#raghu">
          <div className="row">
            <div className="service-title">
              <h3>
                Best Fertility Treatment in Bangalore | Mysore |Ramanagara
              </h3>
            </div>
          </div>
          <div className="row ">
            <div className="col-lg-3 col-md-6 ">
              <div className="serviceBox">
                <div className="service-icon">
                  <span>
                    <img src={img1} />
                  </span>
                </div>
                <h3 className="title">
                  {" "}
                  <span>Ovulation Induction </span>
                </h3>
                <p className="description">
                  Medications are given to induce ovulation, followed by timed
                  intercourse or artificial insemination for better chances of
                  conception.
                </p>
                {/* <a href="#" className="read-more">
                <i className="far fa-arrow-alt-circle-right"></i>
              </a> */}
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="serviceBox ">
                <div className="service-icon">
                  <span>
                    <img src={img2} />
                  </span>
                </div>
                <h3 className="title">
                  <span>IUI</span>
                </h3>
                <p className="description">
                  IUI facilitates fertilization as the sperm is directly
                  inserted into the uterus whereby it surpasses the hostile
                  cervical factors and its journey towards the egg is shortened.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="serviceBox">
                <div className="service-icon">
                  <span>
                    <img src={img3} />
                  </span>
                </div>
                <h3 className="title">
                  <span>IVF</span>
                </h3>
                <p className="description">
                  A type of assisted reproduction where the egg and the sperms
                  are allowed to fertilize naturally by placing them in a petri
                  dish in the lab.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="serviceBox">
                <div className="service-icon">
                  <span>
                    <img src={img4} />
                  </span>
                </div>
                <h3 className="title">
                  <span>ICSI</span>
                </h3>
                <p className="description">
                  ICSI, another type of assisted reproduction, improves the
                  chances of fertilization as the sperm is directly injected
                  into the egg.
                </p>
              </div>
            </div>
          </div>
          <br />
          <div className="row justify-content-center">
            <div className="col-lg-3 col-md-6 ">
              <div className="serviceBox">
                <div className="service-icon">
                  <span>
                    <img src={img5} height="100px" width="100px" />
                  </span>
                </div>
                <h3 className="title">
                  {" "}
                  <span>Genetic Counselling & Testing</span>
                </h3>
                <p className="description">
                  Testing for various chromosomal abnormalities helps the couple
                  to have a healthy baby.
                </p>
                {/* <a href="#" className="read-more">
                <i className="far fa-arrow-alt-circle-right"></i>
              </a> */}
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="serviceBox ">
                <div className="service-icon">
                  <span>
                    <img src={img6} height="100px" width="100px" />
                  </span>
                </div>
                <h3 className="title">
                  <span>Andrology</span>
                </h3>
                <p className="description">
                  Andrology involves treatment of various conditions, causing
                  male infertility and male sexual dysfunction.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="serviceBox">
                <div className="service-icon">
                  <span>
                    <img src={img7} height="100px" width="100px" />
                  </span>
                </div>
                <h3 className="title">
                  <span>Fertility Preservation</span>
                </h3>
                <p className="description">
                  Fertility can be preserved by either freezing the eggs, sperm
                  or even the embryo to be used at a later date.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* \\\\\\\\\\
      --------
      About US
      ------
      ///////
       */}
        {/* <br />
        <br />
        <div className="container-fluid">
          <div className="row about">
            <div className="col-sm-5 col-md-4">
              <div className="about-title">
                <h3>Why Choose Us?</h3>
              </div>
            </div>
            <div className="col-sm-7 col-md-8">
              <div className="about-description ">
                <p>
                  At Kangaroo care, we believe in providing Quality Care for
                  everyone such that our measure of success is in the number of
                  smiles we leave behind.{" "}
                </p>
                <p>
                  To provide the highest level of service and to give our
                  patients an enjoyable and memorable experience we consider all
                  our patients as our own family and treat them the way we would
                  want to be treated elsewhere.
                </p>
                <p>
                  {" "}
                  We believe in following standards and protocols to ensure that
                  all the patients get the best results. We aim to be a leading
                  source of the research center and pass the benefit to patients
                  with the newer advances and strive for continuous improvement.
                </p>
                <p>
                  Patient-centric quality care with commitment and compassion.
                </p>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br /> */}

        <br />
        <br />
        <div className="choose-us-main-container">
          <h2>Why Choose Us</h2>
          <div className=" choose-us-container d-flex justify-content-center">
            <div className="chhose-us-card">
              <img src={serviceImg1} />
              <h3>Customized treatment</h3>
            </div>
            <div className="chhose-us-card">
              <img src={serviceImg2} />
              <h3>Top IVF facilities</h3>
            </div>
            <div className="chhose-us-card">
              <img src={serviceImg3} />
              <h3>Several successful pregnancy</h3>
            </div>
            <div className="chhose-us-card">
              <img src={serviceImg4} />
              <h3>Quality Care for everyone</h3>
            </div>
            <div className="chhose-us-card">
              <img src={serviceImg5} />
              <h3>Following standards and protocols</h3>
            </div>
            <div className="chhose-us-card">
              <img src={serviceImg6} />
              <h3>The newer advances and strive for continuous improvement</h3>
            </div>
            <div className="chhose-us-card">
              <img src={serviceImg7} />
              <h3>highest level of service</h3>
            </div>
            <div className="chhose-us-card">
              <img src={serviceImg8} />
              <h3>
                Patient-centric quality care with commitment and compassion
              </h3>
            </div>
          </div>
        </div>
        <br />
        <br />
        {/* \\\\\\\\\\
      --------
      Testimonials
      ------
      ///////
       */}

        <div className="testimonials-section ">
          <div className="testimonials-section-top-text">
            <h2 className=" mt-md-4">Testimonials</h2>
          </div>

          <OwlCarousel
            items={1}
            className="owl-carousel-blog testimonials-slider"
            data-aos="fade-up"
            data-aos-delay="100"
            loop
            autoplay={true}
            autoplayTimeout={4000}
            margin={2}
            dots={true}
            nav={false}
            navText={[
              '<i className="icofont-simple-up newStyle"></i>',
              '<i className="icofont-simple-up"></i>',
            ]}
          >
            <div className="testimonials-item">
              <div className="image_container">
                <div className="testimonial_google">
                  <img src="/google-01.png" />
                </div>
                <div className="testimonial_star">
                  <img src="/5-star.png" />
                </div>
              </div>
              <p id="kindlyLeft">
                We consider overselves most fortunate to have consulted in
                kangaroo care fertility center Bangalore. The facility of
                Kangaroo Care Fertility is wonderful, the medical staff is well
                experienced and caring, and the adminstrative staff is extreamly
                pleasant and efficient. We were beyond impressed with the kind
                of care we got care and experience. Thanks to all doctors and
                staff Kangaroo Care Fertility.
              </p>
              <p className="testimonials-item-name"> Monica & Vinay</p>
            </div>
            <div className="testimonials-item">
              <div className="image_container">
                <div className="testimonial_google">
                  <img src="/google-01.png" />
                </div>
                <div className="testimonial_star">
                  <img src="/5-star.png" />
                </div>
              </div>
              <p>
                We are thankful to Kangaroo care, fertility center for the best
                services. The doctors are very good and the team is very honored
                and polite enough to keep us informed about all The processes
                and procedures. All the facilities available under one root and
                ambience maintained, neat and clean.
              </p>
              <p className="testimonials-item-name"> Shnuddha Reddy </p>
            </div>
            <div className="testimonials-item">
              <div className="image_container">
                <div className="testimonial_google">
                  <img src="/google-01.png" />
                </div>
                <div className="testimonial_star">
                  <img src="/5-star.png" />
                </div>
              </div>
              <p>
                The overall experience from Kangaroocare fertility was really
                good. Every doctors and staff are very caring and supportive.
                They will guide you like mothers and care for every women.
                Thanks again and recommended others also to this hospital
              </p>
              <p className="testimonials-item-name"> Jyothi, Hari </p>
            </div>
          </OwlCarousel>
        </div>
        {/* \\\\\\\\\\
      --------
      Our Doctors
      ------
      ///////
       */}

        <div className="our-doctors-main-container">
          <div>
            <h3>Our IVF and Fertility Doctors</h3>
            <p>
              First step towards infertility is correct diagnosis and NOT
              treatment.
            </p>
          </div>
          <div className="our-doctors-container">
            <div className="our-doctors-card">
              <img src="https://kangaroocarefertility.com/static/media/divya.f36f7b08.jpg" />
              <div className="doctors-card-address">
                <h3>Dr. DIVYASREE D</h3>
                <p className="doctor-speciality-title">
                  CONSULTANT-FERTILITY SPECIALIST
                </p>
                <p className="doctor-speciality-designation">
                  MBBS, MS (OBG), FRM Fellowship in Reproductive Medicine
                </p>
                <div className="doc-card-button">
                  <a href="#header">Talk to an Expert!</a>
                </div>
              </div>
            </div>
            <div className="our-doctors-card">
              <img src="https://kangaroocarefertility.com/static/media/kavya.7e3f7b98.jpg" />
              <div className="doctors-card-address">
                <h3>Dr. KAVYA KRISHNAPPA</h3>
                <p className="doctor-speciality-title">
                  CONSULTANT-FERTILITY SPECIALIST
                </p>
                <p className="doctor-speciality-designation">
                  MBBS, MS (OBG) Fellowship in Reproductive Medicine
                </p>
                <div className="doc-card-button">
                  <a href="#header">Talk to an Expert!</a>
                </div>
              </div>
            </div>
            {/* <div className="our-doctors-card">
              <img src="https://kangaroocarefertility.com/static/media/dr-4.02368334.jpg" />
              <div className="doctors-card-address">
                <h3>Dr. SRINIVAS BV</h3>
                <p className="doctor-speciality-title">
                  CONSULTANT-ANDROLOGIST & UROLOGIST
                </p>
                <p className="doctor-speciality-designation">
                  MBBS, MS DNB- Urology
                </p>
                <div className="doc-card-button">
                  <a href="#header">Talk to an Expert!</a>
                </div>
              </div>
            </div> */}
            {/* <div className="our-doctors-card">
              <img src="https://kangaroocarefertility.com/static/media/dr-1.c1978b44.jpg" />
              <div className="doctors-card-address">
                <h3>Dr. CHANDAN</h3>
                <p className="doctor-speciality-title">
                  CONSULTANT - INHOUSE EMBRYOLOGIST
                </p>
                <p className="doctor-speciality-designation">
                  MSc, PHD, Embcol PGD
                </p>
                <div className="doc-card-button">
                  <a href="#header">Talk to an Expert!</a>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        {/* \\\\\\\\\\
      --------
      Testimonials
      ------
      ///////
       */}

        <footer className="container-fluid bg-secondary">
          <div className="row text-white">
            <div
              className="col-md-4 col-sm-12 order-1 order-sm-0 p-4 "
              style={{ backgroundColor: "#7E52A0" }}
            >
              <div className="footer-logo footer_logo_container">
                <Link title="Kangaroo Care - Women & Child Hospital" to={`#}`}>
                  <img
                    className="footer-logo pb-4"
                    src={kcf_white_logo}
                    alt="Kangaroo Care Fertility Logo in White"
                  />
                </Link>
                <p>Kangaroo Care Fertility: Nurturing Dreams into Reality</p>
                <p>
                  At Kangaroo Care Fertility, we are dedicated to offering
                  personalized, cost-effective fertility treatments that cater
                  to your unique needs. Our dedicated team is committed to
                  delivering the highest standard of care with integrity and
                  transparency, ensuring that your journey is supportive and
                  ethical. We understand the emotional and physical challenges
                  of fertility, and we stand by you with compassion and
                  expertise every step of the way. Trusted by countless
                  families, we pride ourselves on our successful outcomes and
                  heartfelt connections with our patients.
                </p>
                <p>"Where hope meets happiness."</p>

                <p>
                  Trusted by <strong>many </strong> families
                </p>
              </div>
            </div>
            <div
              className="col-md-8 col-sm-12 order-0 order-sm-1 p-4"
              style={{ backgroundColor: "#9b5ba4" }}
            >
              <div className="row footer_address">
                <div className="col-md-6 mb-3">
                  <div className="services-link">
                    <h3 className="heading">Services</h3>
                    <ul className="list-unstyled">
                      <li>
                        <Link to="/treatments/fertility-testing-for-men">
                          Male Fertility
                        </Link>
                      </li>
                      <li>
                        <Link to="/treatments/fertility-testing-for-women">
                          Female Fertility
                        </Link>
                      </li>
                      <li>
                        <Link to="/treatments/ovulation-induction">
                          Ovulation Induction
                        </Link>
                      </li>

                      <li>
                        <Link to="/treatments/intrauterine">
                          Intrauterine Insemination (IUI)
                        </Link>
                      </li>
                      <li>
                        <Link to="/treatments/invitro-fertilization">
                          In Vitro Fertilisation (IVF)
                        </Link>
                      </li>
                      <li>
                        <Link to="/treatments/intracytoplasmic">
                          Intracytoplasmic Sperm Injection (ICSI)
                        </Link>
                      </li>
                      <li>
                        <Link to="/treatments/genetic-counselling-and-testing">
                          Genetic Counselling and Testing
                        </Link>
                      </li>
                      <li>
                        <Link to="/treatments/varicocele">Andrology</Link>
                      </li>
                    </ul>
                  </div>

                  <div className="col-md-6 mb-3">
                    <div className="follow-link">
                      <h3 className="heading">Follow Us On</h3>
                      <ul className="list-icons">
                        <li>
                          <a href="https://www.linkedin.com/company/kangaroocare/ ">
                            <i class="fab fa-linkedin"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.facebook.com/kangaroocarehospital/">
                            <i class="fab fa-facebook"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.instagram.com/kangaroocareindia/">
                            <i class="fab fa-instagram"></i>
                          </a>
                        </li>

                        <li>
                          <a href="https://x.com/KangarooCare3">
                            <i class="fab fa-twitter"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 mb-3">
                  <div className="row d-flex justify-content-between ">
                    <div className="col-md-12 contact-info-link">
                      <h3 className="heading first_heading">
                        Bangalore Contact Info
                      </h3>
                      <address>
                        <p>
                          #119, 17th cross MC Layout, Vijaynagar, Bangalore -
                          560040
                        </p>
                      </address>{" "}
                      <br />
                      {/* <p>
                    <a href="mailto:fertilityblr@kangaroocareindia.com">
                      <i className="fas fa-envelope-open me-2"></i>
                      fertilityblr@kangaroocareindia.com
                    </a>
                  </p>
                  <div className="contact-number">
                    <i className="fas fa-tty me-2"></i>
                    <a href="tel:1800-425-4500"> Toll Free - 1800-425-4500</a>
                  </div> */}
                    </div>
                    <br />
                    <div className="col-md-12 contact-info-link">
                      <h3 className="heading">Mysore Contact Info</h3>
                      <address>
                        <p>
                          #384, New Kalidasa Road, Opposite Kangaroo Care
                          Hospitals, Vijayanagar, 1st Stage, Mysore - 570017
                        </p>
                      </address>{" "}
                      <br />
                      {/* <p>
                    <a href="mailto:fertilityblr@kangaroocareindia.com">
                      <i className="fas fa-envelope-open me-2"></i>
                      fertilityblr@kangaroocareindia.com
                    </a>
                  </p>
                  <div className="contact-number">
                    <i className="fas fa-tty me-2"></i>
                    <a href="tel:1800-425-4500"> Toll Free - 1800-425-4500</a>
                  </div> */}
                    </div>
                    <br />
                    <div className=" col-md-12 contact-info-link">
                      <h3 className="heading">Ramanagara Contact Info</h3>
                      <address>
                        <p>
                          No.32 of Vivekanandanagar, Block-3, North B.M. Road,
                          Ramanagara -562 159 Karnataka
                        </p>
                        <p></p>
                      </address>{" "}
                      <br />
                      {/* <p>
                    <a href="mailto:fertilityblr@kangaroocareindia.com">
                      <i className="fas fa-envelope-open me-2"></i>
                      fertilityblr@kangaroocareindia.com
                    </a>
                  </p>
                  <div className="contact-number">
                    <i className="fas fa-tty me-2"></i>
                    <a href="tel:1800-425-4500"> Toll Free - 1800-425-4500</a>
                  </div> */}
                      <p>
                        <a href="mailto:fertilitymarketing@kangaroocareindia.com">
                          <i className="fas fa-envelope-open me-2"></i>
                          fertilitymarketing@kangaroocareindia.com
                        </a>
                      </p>
                      <div className="contact-number">
                        <i className="fas fa-tty me-2"></i>
                        <a href="tel:1800-425-4500">
                          {" "}
                          Toll Free - 1800-425-4500
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12 mb-3">
                  {/* <div className="row d-flex justify-content-between ">
                <div className="col-md-4 contact-info-link">
                  <h3 className="heading first_heading">
                    Bangalore Contact Info
                  </h3>
                  <address>
                    <p>
                      #119, 8th cross MC Layout, Vijaynagar, Bangalore - 560040
                    </p>
                  </address>{" "}
                  <p>
                    <a href="mailto:fertilityblr@kangaroocareindia.com">
                      <i className="fas fa-envelope-open me-2"></i>
                      fertilityblr@kangaroocareindia.com
                    </a>
                  </p>
                  <div className="contact-number">
                    <i className="fas fa-tty me-2"></i>
                    <a href="tel:1800-425-4500"> Toll Free - 1800-425-4500</a>
                  </div>
                </div>
                <br />
                <div className="col-md-4 contact-info-link">
                  <h3 className="heading">Mysore Contact Info</h3>
                  <address>
                    <p>
                      #505, Kalidasa road, Vijayanagar 1st stage, Opp. MUDA
                      Complex Mysore - 570017.
                    </p>
                  </address>{" "}
                  <p>
                    <a href="mailto:fertilityblr@kangaroocareindia.com">
                      <i className="fas fa-envelope-open me-2"></i>
                      fertilityblr@kangaroocareindia.com
                    </a>
                  </p>
                  <div className="contact-number">
                    <i className="fas fa-tty me-2"></i>
                    <a href="tel:1800-425-4500"> Toll Free - 1800-425-4500</a>
                  </div>
                </div>
                <br />
                <div className=" col-md-4 contact-info-link">
                  <h3 className="heading">Ramanagara Contact Info</h3>
                  <address>
                    <p>
                      No.32 of Vivekanandanagar, Block-3, North B.M. Road,
                      Ramanagara -562 159 Karnataka
                    </p>
                    <p></p>
                  </address>{" "}
                  <p>
                    <a href="mailto:fertilityblr@kangaroocareindia.com">
                      <i className="fas fa-envelope-open me-2"></i>
                      fertilityblr@kangaroocareindia.com
                    </a>
                  </p>
                  <div className="contact-number">
                    <i className="fas fa-tty me-2"></i>
                    <a href="tel:1800-425-4500"> Toll Free - 1800-425-4500</a>
                  </div>
                </div>
              </div> */}
                </div>
              </div>
            </div>
          </div>
          <div className="row py-2">
            <div className="col-12 text-center text-white">
              © Managed & Maintained by <a href="http://digikit.in"> DIGIKIT</a>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}
