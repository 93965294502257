import React from "react";
import "./navbar.css";
import kcf_logo1 from "./kcf_logo1.png";
import nabh_logo from "./nabh.png";
import { useLocation, Link } from "react-router-dom";
export default function Image() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <>
      <div className="sticky-top sticky-top-background">
        <header>
          <nav>
            <div className="container" id="nav-header-top">
              <div className="row justify-content-between align-items-center">
                <div className="col-md-3 col-sm-5">
                  {/* <img src={nabh_logo} /> */}
                  <a href="/">
                    <img
                      className="img-responsive"
                      src={kcf_logo1}
                      style={{ width: "100%", height: "auto" }}
                      alt="logo"
                    />
                  </a>
                </div>
                <div className="col-md-9 col-sm-7 ms-auto">
                  <div className="row ms-auto justify-content-center align-items-center">
                    <div className="col-lg-9 col-md-5 d-md-block d-none text-center header_buttons">
                      <div className="header_buttons_wrapper">
                        {" "}
                        <div className="knagaroo-contact mt-2">
                          <i className="fas fa-phone-alt me-2"></i>
                          <span
                            style={{ color: "#7e52a0", fontWeight: "bold" }}
                          >
                            <a
                              href="tel:18004254500"
                              style={{ color: "#7e52a0", fontWeight: "bold" }}
                            >
                              Toll Free - 1800-425-4500
                            </a>
                            <br />
                          </span>
                        </div>
                        <div>
                          {" "}
                          <a
                            className="btn btn-danger me-3"
                            style={{
                              backgroundColor: "#7e52a0",
                              color: "#fff",
                              border: "none",
                            }}
                            href="/appointments"
                            role="button"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Book Appointment
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-5 d-md-block d-none text-center header_buttons">
                      <a
                        className="btn btn-danger me-3"
                        style={{
                          backgroundColor: "#EF586F",
                          color: "#fff",
                          border: "none",
                        }}
                        href="https://www.kangaroocareindia.com/"
                        role="button"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Kangaroocare
                      </a>
                    </div>
                    <div
                      className="col-lg-0 col-sm-2 d-lg-none d-block"
                      id="small-screen-navbars"
                    >
                      <Link
                        data-bs-toggle="offcanvas"
                        to="#offcanvasExample"
                        role="button"
                        aria-controls="offcanvasExample"
                      >
                        <i
                          className="fas fa-bars text-dark p-3"
                          id="secondToggle"
                        ></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </header>
        <nav className="navbar navbar-expand-lg " id="navDrop">
          <div className="container">
            <div className="collapse navbar-collapse">
              <ul className="navbar-nav nav-fill w-100 align-items-start">
                <li className="nav-item">
                  <Link to="/" className="nav-link">
                    Home
                  </Link>
                </li>
                {/* <li className="nav-item">
                <Link to="/about-us" className="nav-link">
                About us
                </Link>
              </li> */}

                <li className="nav-item dropdown">
                  <Link to="/about-us" className="nav-link dropdown-toggle">
                    About us
                  </Link>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <li>
                      <Link className="dropdown-item" to="/operational-team">
                        Operational Team
                      </Link>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/admin-team">
                        Admin Team
                      </Link>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                  </ul>
                </li>

                <li className="nav-item dropdown">
                  <Link to="#" className="nav-link dropdown-toggle">
                    Our Centers
                  </Link>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <li>
                      <a
                        className="nav-link "
                        style={{
                          backgroundColor: "#7E52A0",
                          color: "#fff",
                          border: "none",
                        }}
                        href="/centers/bengaluru"
                        role="button"
                        rel="noreferrer"
                      >
                        Bangalore
                      </a>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li>
                      <a
                        className="nav-link"
                        style={{
                          backgroundColor: "#7E52A0",
                          color: "#fff",
                          border: "none",
                        }}
                        href="/centers/mysore"
                        role="button"
                        rel="noreferrer"
                      >
                        Mysore
                      </a>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>

                    <li>
                      <a
                        className="nav-link"
                        style={{
                          backgroundColor: "#7E52A0",
                          color: "#fff",
                          border: "none",
                        }}
                        href="/centers/ramanagara"
                        role="button"
                        rel="noreferrer"
                      >
                        Ramanagara
                      </a>
                    </li>
                  </ul>
                </li>

                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="doctorsDropDown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Our Doctors
                  </a>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="doctorsDropDown"
                  >
                    <li>
                      <a className="dropdown-item" href="/doctors/bangalore">
                        Bangalore
                      </a>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <a className="dropdown-item" href="/doctors/mysore">
                        Mysore
                      </a>
                    </li>
                    <hr className="dropdown-divider" />
                    <li>
                      <a className="dropdown-item" href="/doctors/ramanagara">
                        Ramanagara
                      </a>
                    </li>
                  </ul>
                </li>

                <li className="nav-item dropdown">
                  <span
                    className="nav-link dropdown-toggle"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Fertility
                  </span>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/treatments/fertility-testing-for-men"
                      >
                        Male Fertility
                      </Link>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/treatments/fertility-testing-for-women"
                      >
                        Female Fertility
                      </Link>
                    </li>
                  </ul>
                </li>

                <li className="nav-item dropdown">
                  {/*
            <a className="nav-link dropdown-toggle" href="/treatments" id="navbarDropdownMenuLink" // role="button"
              data-bs-toggle="dropdown" aria-expanded="false" // onClick={navigateTo}>
            Treatments
            </a> */}
                  <Link
                    to="/fertility-treatments"
                    className="nav-link dropdown-toggle"
                  >
                    Treatments
                  </Link>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink"
                  >
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/treatments/ovulation-induction"
                      >
                        Ovulation induction
                      </Link>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/treatments/intrauterine"
                      >
                        Intrauterine Insemination (IUI)
                      </Link>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/treatments/invitro-fertilization"
                      >
                        In Vitro Fertilisation (IVF)
                      </Link>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/treatments/intracytoplasmic"
                      >
                        Intracytoplasmic Sperm Injection (ICSI)
                      </Link>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/treatments/genetic-counselling-and-testing"
                      >
                        Genetic Counselling and Testing
                      </Link>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li className="dropdown-submenu">
                      <Link
                        className="dropdown-item dropdown-toggle"
                        to="/treatments/varicocele"
                      >
                        Andrology
                      </Link>
                      <ul className="dropdown-menu">
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/treatments/varicocele"
                          >
                            Varicocele
                          </Link>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            to="/treatments/tesa-pesa"
                          >
                            TESA / PESA
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/treatments/fertility-preservation"
                      >
                        Fertility Preservation
                      </Link>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/treatments/donor">
                        Donor
                      </Link>
                    </li>
                  </ul>
                </li>
                {/* <li className="nav-item">
                <Link
                  to="/doctors/fertility-specialist-in-bangalore"
                  className="nav-link"
                  data-bs-dismiss="offcanvas"
                >
                  Our Doctors
                </Link>
              </li> */}

                {/* <li className="nav-item">
                  <Link to="/facility" className="nav-link">
                    Facilities
                  </Link>
                </li> */}
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Gallery
                  </a>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <li>
                      <a className="dropdown-item" href="/gallery/images">
                        Images
                      </a>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <a className="dropdown-item" href="/gallery/videos">
                        Videos
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <span
                    className="nav-link dropdown-toggle"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Media
                  </span>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <li>
                      <Link className="dropdown-item" to="/media/blogs">
                        Our Blogs
                      </Link>
                    </li>{" "}
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/media/360-view">
                        360 View
                      </Link>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    {/* <li>
                <a className="dropdown-item" href="/media/Events">
                Events
                </a>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li> */}
                    <li>
                      <Link className="dropdown-item" to="/media/Faq">
                        FAQ
                      </Link>
                    </li>
                    {/* <li>
                <hr className="dropdown-divider" />
              </li>*/}
                    <hr className="dropdown-divider" />
                    <li>
                      <a className="dropdown-item" href="/media/press">
                        Press Release
                      </a>
                    </li>
                    <hr className="dropdown-divider" />
                    <li>
                      <a className="dropdown-item" href="/media/camp">
                        Camp
                      </a>
                    </li>
                    <hr className="dropdown-divider" />
                    <li>
                      <a className="dropdown-item" href="/media/Events">
                        Events
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <Link to="/careers" className="nav-link">
                    Careers
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/contact" className="nav-link">
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div
          className="offcanvas offcanvas-start mb-2"
          tabIndex="-1"
          id="offcanvasExample"
          aria-labelledby="offcanvasExampleLabel"
        >
          <div className="offcanvas-body">
            <div className="mb-5">
              <span
                type="button"
                className="btn-close text-reset float-end me-2 mt-3 border p-2 text-white bg-white"
                id="for-folded"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></span>
            </div>
            {/* <div className="offcanvas-header">
      <ul className="navbar-nav">
        <li>
          <hr className="dropdown-divider" />
        </li>
        <a href="/"><img src={logo} alt="Not available" /></a>
        <li>
          <hr className="dropdown-divider" />
        </li>
        <div className="knagaroo-appointment-book my-2">
          <button type="button" className="btn btn-danger ms-3" data-bs-dismiss="offcanvas">
            Book an Appointment
          </button>
        </div>
        <li>
          <hr className="dropdown-divider my-3" />
        </li>
        <div className="knagaroo-address" data-bs-dismiss="offcanvas">
          <i className="fas fa-map-marker-alt me-2"></i>
          <span className="ms-3">
          #89, 17th cross, MC layout, Near,, <br />
            <a href="#" className="ms-5">
            Balagangadharnath Swamy Metro Station,<br/>
                &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; Vijayanagar, Bangalore 560040.
            </a>
          </span>
        </div>
        <li>
          <hr className="dropdown-divider my-3" />
        </li>
        <div className="knagaroo-address" data-bs-dismiss="offcanvas">
          <i className="fas fa-phone-alt me-2" style={{ fontSize: "18px" }}></i>
          <span className="ms-3">
            080-23015678 <br /> <a href="#" className="ms-5"> Call Us For Enquiry </a>
          </span>
        </div>
      </ul>
    </div>
       */}
            <ul className="navbar-nav mt-0 pt-0" id="nav-sml-bar">
              <li className="nav-item" id="smlScrn">
                <Link to="/" className="nav-link" data-bs-dismiss="offcanvas">
                  Home
                </Link>
              </li>
              {/* <li className="nav-item">
              <Link
                to="/about-us"
                className="nav-link"
                data-bs-dismiss="offcanvas"
              >
                About us
              </Link>
            </li> */}

              <li className="nav-item dropdown">
                <Link to="/about-us" style={{ display: "inline" }}>
                  <span className="nav-link" style={{ display: "inline" }}>
                    {" "}
                    About us &nbsp;
                  </span>
                </Link>
                <span
                  className="nav-link dropdown-toggle"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ display: "inline" }}
                ></span>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/admin-team"
                      data-bs-dismiss="offcanvas"
                    >
                      Admin Team
                    </Link>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/operational-team"
                      data-bs-dismiss="offcanvas"
                    >
                      Operational Team
                    </Link>
                  </li>
                </ul>
              </li>

              <li className="nav-item dropdown">
                <Link to="#" style={{ display: "inline" }}>
                  <span className="nav-link" style={{ display: "inline" }}>
                    {" "}
                    Our Centers &nbsp;
                  </span>
                </Link>
                <span
                  className="nav-link dropdown-toggle"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{ display: "inline" }}
                ></span>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <a
                      className="nav-link "
                      style={{
                        backgroundColor: "#7E52A0",
                        color: "#fff",
                        border: "none",
                      }}
                      href="/centers/bengaluru"
                      role="button"
                      rel="noreferrer"
                    >
                      Bangalore
                    </a>{" "}
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a
                      className="nav-link "
                      style={{
                        backgroundColor: "#7E52A0",
                        color: "#fff",
                        border: "none",
                      }}
                      href="/centers/mysore"
                      role="button"
                      rel="noreferrer"
                    >
                      Mysore
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <a
                      className="nav-link "
                      style={{
                        backgroundColor: "#7E52A0",
                        color: "#fff",
                        border: "none",
                      }}
                      href="/centers/ramanagara"
                      role="button"
                      rel="noreferrer"
                    >
                      Ramanagara
                    </a>
                  </li>
                </ul>
              </li>

              <li className="nav-item dropdown">
                <span
                  className="nav-link dropdown-toggle"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Fertility
                </span>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/treatments/fertility-testing-for-men"
                      data-bs-dismiss="offcanvas"
                    >
                      Male Fertility
                    </Link>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/treatments/fertility-testing-for-women"
                      data-bs-dismiss="offcanvas"
                    >
                      Female Fertility
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                {/*
          <a className="nav-link dropdown-toggle" href="/treatments" id="navbarDropdownMenuLink" role="button"
            data-bs-toggle="dropdown" aria-expanded="false">
          Treatments
          </a> */}
                <span className="nav-link dropdown-toggle">Treatments</span>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/treatments/ovulation-induction"
                      data-bs-dismiss="offcanvas"
                    >
                      Ovulation induction
                    </Link>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/treatments/intrauterine"
                      data-bs-dismiss="offcanvas"
                    >
                      Intrauterine Insemination (IUI)
                    </Link>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/treatments/invitro-fertilization"
                      data-bs-dismiss="offcanvas"
                    >
                      In Vitro Fertilisation (IVF)
                    </Link>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/treatments/intracytoplasmic"
                      data-bs-dismiss="offcanvas"
                    >
                      Intracytoplasmic Sperm Injection (ICSI)
                    </Link>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/treatments/genetic-counselling-and-testing"
                      data-bs-dismiss="offcanvas"
                    >
                      Genetic Counselling and Testing
                    </Link>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li className="dropdown-submenu">
                    <Link
                      className="dropdown-item dropdown-toggle"
                      to="/treatments/varicocele"
                    >
                      Andrology
                    </Link>
                    <ul className="dropdown-menu">
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/treatments/vericocele"
                          data-bs-dismiss="offcanvas"
                        >
                          Varicocele
                        </Link>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to="/treatments/tesa-pesa"
                          data-bs-dismiss="offcanvas"
                        >
                          TESA / PESA
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/treatments/fertility-preservation"
                      data-bs-dismiss="offcanvas"
                    >
                      Fertility Preservation
                    </Link>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/treatments/donor"
                      data-bs-dismiss="offcanvas"
                    >
                      Donor
                    </Link>
                  </li>
                </ul>
              </li>
              {/* <li className="nav-item">
              <Link
                to="/doctors/fertility-specialist-in-bangalore"
                className="nav-link"
                data-bs-dismiss="offcanvas"
              >
                Our Doctors
              </Link>
            </li> */}

              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Our Doctors
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <a className="dropdown-item" href="/doctors/bangalore">
                      Bangalore
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <a className="dropdown-item" href="/doctors/mysore">
                      Mysore
                    </a>
                  </li>
                  <hr className="dropdown-divider" />
                  <li>
                    <a className="dropdown-item" href="/doctors/ramanagara">
                      Ramanagara
                    </a>
                  </li>
                </ul>
              </li>

              {/* <li className="nav-item">
                <Link
                  to="/facility"
                  className="nav-link"
                  data-bs-dismiss="offcanvas"
                >
                  Facilities
                </Link>
              </li> */}
              {/* <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown"
            aria-expanded="false">
          Gallery
          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li>
              <a className="dropdown-item" href="/gallery/images" data-bs-dismiss="offcanvas">
              Images
              </a>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li>
            <li>
              <a className="dropdown-item" href="/gallery/videos" data-bs-dismiss="offcanvas">
              Videos
              </a>
            </li>
          </ul>
        </li> */}
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Gallery
                </a>
                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li>
                    <a className="dropdown-item" href="/gallery/images">
                      Images
                    </a>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <a className="dropdown-item" href="/gallery/videos">
                      Videos
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <span
                  className="nav-link dropdown-toggle"
                  id="navbarDropdownMedia"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Media
                </span>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="navbarDropdownMedia"
                >
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/media/blogs"
                      data-bs-dismiss="offcanvas"
                    >
                      Our Blogs
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/media/360-view"
                      data-bs-dismiss="offcanvas"
                    >
                      360 View
                    </Link>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  {/* <li>
              <a className="dropdown-item" href="/media/Events" data-bs-dismiss="offcanvas">
              Events
              </a>
            </li>
            <li>
              <hr className="dropdown-divider" />
            </li> */}
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/media/Faq"
                      data-bs-dismiss="offcanvas"
                    >
                      FAQ
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/media/camp"
                      data-bs-dismiss="offcanvas"
                    >
                      Camp
                    </Link>
                  </li>
                  {/* <li>
              <hr className="dropdown-divider" />
            </li>
            <li>
              <a className="dropdown-item" href="/media/Press" data-bs-dismiss="offcanvas">
              Press
              </a>
            </li> */}
                </ul>
              </li>
              <li className="nav-item">
                <Link
                  to="/careers"
                  className="nav-link"
                  data-bs-dismiss="offcanvas"
                >
                  Careers
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/contact"
                  className="nav-link"
                  data-bs-dismiss="offcanvas"
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
